import React, { Component, Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation


import { useSelector, useDispatch } from 'react-redux';
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Header from "../component/header/Header";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../elements/Team";
import Accordion01 from "../elements/Accordion";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";

import Portfolio from "../component/HomeLayout/homeOne/Portfolio";


import about from "../assets/images/about/about-4.png";
import about2 from "../assets/images/about/about-3.png";
import { fetchBlogs } from "../elements/blog/blogSlice";
import { useTranslation } from "react-i18next";
import ListComponent from "../elements/list";
import ListOne from "../component/common/List";
import { fetchOurApproach, fetchService, fetchSlide } from "../component/common/servicesSlice";
import Testimonial from "../elements/Testimonial";

 


function Business() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const posts = useSelector(state => state.blog.blogs);
  const loading = useSelector(state => state.blog.loading);
  const service = useSelector(state => state.services.service);
  const approach = useSelector(state => state.services.approach);
  const SlideList2 = useSelector(state => state.services.slide);
  const { i18n, t } = useTranslation();
  const language = i18n.language;

  const PostList = posts.slice(0, 3);

  useEffect(() => {
    const locale = language === 'en' ? 'en-US' : 'fr-CM';
    dispatch(fetchSlide(locale));
    dispatch(fetchBlogs(locale));
    dispatch(fetchService(locale));
    dispatch(fetchOurApproach(locale));
    dispatch(fetchSlide(locale));
  }, [dispatch, language]);


  console.log(service);
  console.log(approach);
  console.log("SlideList2", SlideList2)

  return (
    <Fragment>
      <Helmet pageTitle="Lessential" />

      <HeaderThree homeLink="/" logo="logo2" color="color-white" />



      {/* Start Slider Area   */}
      {SlideList2.length > 0 && (
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList2.map((value, index) => (


                <div




key={index}
                >
                  <div
                    className="slide  slide-style-2 d-flex align-items-center justify-content-center "
                    style={{
                      backgroundImage: `url(${value.fields.image.fields.file.url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100vh"
                    }}
                    data-black-overlay="7"
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className={`inner text-left`}>
                            {/* {value.category ? <span>{value.category}</span> : ""} */}

                            <h1 className="title">{value.fields.title}</h1>



                            <p className="description">{value.fields.description}</p>


                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.fields.buttonLink}`}
                              >
                                {value.fields.buttonText}
                              </a>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
      {/* End Slider Area   */}
      {/* Start Featured Service Area  */}
      <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
        <div className="container">
          <div className="row">
            {/* Start Single Service  */}
            <div className="col-lg-3 col-md-6 col-12 mt--30">
              <div className="section-title">
                <h2 className="title">{service?.fields?.title}</h2>
                <p>
                  {service?.fields?.intro}
                </p>
                <div className="service-btn">
                  <a className="btn-transparent rn-btn-dark" href="/service">
                    <span className="text">{t('contact.title')}</span>
                  </a>
                </div>
              </div>
            </div>
            {/* End Single Service  */}

            {/* Start Single Service  */}
            <div className="col-lg-9">
              <div className="row">

                <div className="col-lg-4 col-md-4 mt--10"  >
                  <div className="standard-service">
                    <div className="thumbnai">
                      <img
                        src={service.fields?.image?.fields?.file?.url}
                        alt="Corporate Images"
                      />
                    </div>
                    <div className="content">
                      <h3>
                        <a href="/service"> {service?.fields?.service}</a>
                      </h3>
                      <p>{service?.fields?.description}</p>
                      <a
                        className="btn-transparent rn-btn-dark"
                        href="/service"
                      >
                        <span className="text"> {t('blog.readMore')}</span>
                      </a>
                    </div>
                  </div>

                </div>
                <div className="col-lg-4 col-md-4 mt--10"  >
                  <div className="standard-service">
                    <div className="thumbnai">
                      <img
                        src={service?.fields?.image1?.fields?.file?.url}
                        alt="Corporate Images"
                      />
                    </div>
                    <div className="content">
                      <h3>
                        <a href="/service"> {service?.fields?.service1}</a>
                      </h3>
                      <p>{service?.fields?.description1}</p>
                      <a
                        className="btn-transparent rn-btn-dark"
                        href="/service"
                      >
                        <span className="text">{t('blog.readMore')}</span>
                      </a>
                    </div>
                  </div>

                </div>
                <div className="col-lg-4 col-md-4 mt--10"  >
                  <div className="standard-service">
                    <div className="thumbnai">
                      <img
                        src={service?.fields?.image2?.fields?.file?.url}
                        alt="Corporate Images"
                      />
                    </div>
                    <div className="content">
                      <h3>
                        <a href="/service"> {service?.fields?.service2}</a>
                      </h3>
                      <p>{service?.fields?.description2}</p>
                      <a
                        className="btn-transparent rn-btn-dark"
                        href="/service"
                      >
                        <span className="text">{t('blog.readMore')}</span>
                      </a>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            {/* End Single Service  */}
          </div>
        </div>
      </div>
      {/* End Featured Service Area  */}
      {/* Start About Area  */}
      <div className="rn-about-area ptb--80 bg_color--5">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6">
              <div className="thumbnail">
                <img className="w-100" src={approach?.fields?.image?.fields?.file?.url} alt="About Images" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title">{approach?.fields?.title}</h2>
                  <p>
                    {approach?.fields?.description}
                  </p>
                </div>
                <div className="accordion-wrapper mt--30">
                  <Accordion01 approach={approach?.fields?.process} />
                </div>
                <div className="about-button mt--50">
                  <a className="rn-button-style--2 btn-solid" href="/contact">
                    {t('about.knowMore')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start Portfolio Area */}
      <div className="portfolio-area ptb--120 bg_color--1">
        <div className="portfolio-sacousel-inner mb--55">
          <Portfolio />
        </div>
      </div>
      {/* End Portfolio Area
      {/* Start Blog Area */}
      <div className="rn-blog-area pt--20 pb--80 bg_color--5">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center">
                <h2 className="title">{t('blog.expert')}</h2>
                <p>
                  {t('blog.popular')}
                </p>
              </div>
            </div>
          </div>
          <div className="row mt--60">
            {PostList.map((value, i) => {
              console.log(value);
              const blogImage = value.fields.featuredImage.fields.file.url;
              const blogTitle = value.fields.title;
              const blogDescription = value.fields.description;
              const blogCategory = value.fields.category;
              const blogDate = value.fields.date;
              const blogAuthor = value.fields.author;
              const blogSlug = value.fields.slug;
              const blogId = value.sys.id;
              const blogUrl = `/blog-details?id=${blogId}`;
              return (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <a href={blogUrl} >
                        <img
                          className="w-100 w-[390px] h-[532px] object-cover"
                          src={blogImage}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{blogCategory}</p>
                      <h4 className="title">
                        <a href={blogUrl}>{blogTitle}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href={blogUrl}>
                          {t('blog.readMore')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* End Blog Area */}
      {/* Start Testimonial Area */}
      <div className="rn-testimonial-area bg_color--5 ptb--120">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      {/* End Testimonial Area */}
      {/* Start Brand Area */}
      <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}
      {/* Start call To Action  */}
      <CallAction />
      {/* End call To Action  */}

      {/* Start Footer Style  */}
      <FooterTwo />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </Fragment>
  );
}

export default Business;

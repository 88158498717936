import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import about from "../assets/images/about/about-3.jpg";
import findingImg from "../assets/images/about/finding-us-01.png";
import teamImg1 from "../assets/images/team/team-01.jpg";
import teamImg2 from "../assets/images/team/team-02.jpg";
import teamImg3 from "../assets/images/team/team-03.jpg";
import { fetchAbout, fetchMission, fetchWhyChoose, fetchVision, fetchOurAppraoch, fetchOurcommitment } from "./about/aboutSlice";
import Brand from "./Brand";
import HeaderThree from "../component/header/HeaderThree";
import { useTranslation } from "react-i18next";

function About() {
  
  const dispatch = useDispatch();
  const aboutData = useSelector(state => state.about.about);
  const whyChooseUs = useSelector(state => state.about.whyChooseUs)
  const mission = useSelector(state => state.about.mission)
  const vision = useSelector(state => state.about.vision)
  const approach = useSelector(state => state.about.approach)
  const ourcommitment = useSelector(state => state.about.ourcommitment)
  const { i18n ,t} = useTranslation();
  const language = i18n.language;
  useEffect(() => {
    const locale = language === 'en' ? 'en-US' : 'fr-CM';
    dispatch(fetchAbout(locale));
    dispatch(fetchWhyChoose(locale));
    dispatch(fetchMission(locale))
    dispatch(fetchVision(locale))
    dispatch(fetchOurAppraoch(locale))
    dispatch(fetchOurcommitment(locale))
  }, [dispatch,language]);

  return (
    <React.Fragment>
      <PageHelmet pageTitle={t("about.pageTitle")} />

      <HeaderThree homeLink="/" logo="logo2" color="color-white" />
      


      <Breadcrumb title={"About"} />
      {/* End Breadcrump Area */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={aboutData?.image?.fields?.file?.url}
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{aboutData.title}</h2>
                    {documentToReactComponents(aboutData.description)}
                    {/* <p className="description">{description}</p> */}
                  </div>
                  <h2 className="title mt--10 mb--0"> {t("about.ourVision")}</h2>
                  <div className="row ">

                    {vision && vision.length > 0 ? (
                      vision.map((item, index) => (
                        <div key={index} className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list">
                            <h3 className="title">{item.fields.title}</h3>
                            <p>
                              {item.fields.description}
                            </p>
                          </div>
                        </div>
                      )))
                      : (
                        <p>Loading reasons to choose us...</p> // Or any other placeholder
                      )}



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h3 className="fontWeight500">{t("about.ourMission")}</h3>
              </div>
            </div>
          </div>
          <CounterOne mission={mission} />
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Finding Us Area  */}
      <div className="rn-finding-us-area rn-finding-us bg_color--1">
        <div className="inner">
          <div className="content-wrapper">
            <div className="content">
              <h4 className="theme-gradient">{whyChooseUs.reason}</h4>
              <p>
                {whyChooseUs.description}
              </p>
              <a className="rn-btn btn-white" href="/contact">
              {t("callToAction.button")}
              </a>
            </div>
          </div>
          <div className="thumbnail">
            <div className="image">
              <img src={whyChooseUs?.whychoose?.fields?.file?.url} alt="Finding Images" />
            </div>
          </div>
        </div>
      </div>
      {/* End Finding Us Area  */}
      <div className="rn-about-area ptb--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7">
                <div className="about-inner inner">
                <div className="section-title">
                    <h2 className="title">{approach.title}</h2>
                    <h3 className="title font-weight-normal mt--20 mb--10">{approach?.title1}</h3>
                    {approach.description1}
                    <h3 className="title font-weight-normal mt--20 mb--10">{approach.title2}</h3>
                    {approach.description2}
                    {/* <p className="description">{description}</p> */}
                  </div>
                 
                  
                  <h2 className="title mt--10 mb--0">{ourcommitment?.title}</h2>
                  <div className="row ">


                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title font-weight-normal mt--20 mb--10">{ourcommitment?.title1}</h3>
                        <p>
                          {ourcommitment?.description1}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title font-weight-normal mt--20 mb--10">{ourcommitment?.title2}</h3>
                        <p>
                          {ourcommitment?.description2}
                        </p>
                      </div>
                    </div>

 <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title font-weight-normal mt--20 mb--10">{ourcommitment?.title3}</h3>
                        <p>
                          {ourcommitment?.description3}
                        </p>
                      </div>
                    </div>




                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={aboutData?.image?.fields?.file?.url}
                    alt="About Images"
                  />
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
     

      {/* Start Testimonial Area */}
      <div className="rn-testimonial-area bg_color--5 ptb--120">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      {/* End Testimonial Area */}

      {/* Start Brand Area */}
      <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
}

export default About;

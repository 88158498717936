import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageScrollTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Trigger effect on location change

  return children; // Render the wrapped content
};

export default PageScrollTop;

import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import PortfolioList from "../elements/portfolio/PortfolioList";
import HeaderThree from '../component/header/HeaderThree';
import Gallery from './Gallery';

 

 

const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='Success Stories' />

            {/* Start Header Area  */}
            <HeaderThree homeLink="/" logo="logo2" color="color-white" />
        
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Success Stories'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
 


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">Success Stories</h2>
                                        <p>Discover the inspiring stories of Cameroonian entrepreneurs who have successfully expanded their businesses into the Canadian market with L'Essentiel's support. Their journeys are a testament to the power of partnership, innovation, and the unwavering spirit of Made in Cameroon products.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                 <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="20" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/contact"><span>Be Part</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

               

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Portfolio;
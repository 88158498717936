import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEntries } from '../../component/common/contentful';

const initialState = {
    blogs: [],
    blog: {},
    loading: false,
    error: null,
};

export const fetchBlogs = createAsyncThunk('blog/fetchBlogs', async (locale) => {
    console.log('Fetching blogs...');
    try {
        const response = await fetchEntries('blogPost', locale);
        return response; // Return the fetched data
    } catch (error) {
        console.error('Error fetching blogs:', error);
        throw error;  // Re-throw the error for Redux Toolkit to handle
    }
});


const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogs.pending, (state) => {
                state.loading = true;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchBlogs.fulfilled, (state, action) => {
                state.loading = false;
                state.blogs = action.payload;
            })
            .addCase(fetchBlogs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; // Set the error message
            });
    },
});
 

 

export default blogSlice.reducer;
import React, { Component, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import testimonialImg1 from "../assets/images/client/Screen Shot 2024-07-01 at 12.26.18 PM.png";
import testimonialImg2 from "../assets/images/client/Screen Shot 2024-07-01 at 12.26.34 PM.png";
import testimonialImg3 from "../assets/images/client/Screen Shot 2024-07-01 at 12.26.55 PM.png";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchTestimonials } from "../component/common/servicesSlice";

const Testimonial = () =>{

  const dispatch = useDispatch();
  const testimonies = useSelector(state => state.services.testimonials);
  const { i18n ,t} = useTranslation();
  const language = i18n.language;
  useEffect(() => {
    const locale = language === 'en' ? 'en-US' : 'fr-CM';
    
    dispatch(fetchTestimonials(locale))
  }, [dispatch,language]);
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
            {testimonies.map((testimony, index) => (
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                   {t(testimony.fields.testimonial)}
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>{ testimony.fields.name}  </span> - {testimony.fields.company}
                    </h6>
                  </div>
                </div>
              </TabPanel>
    ))}
               

               

              <TabList className="testimonial-thumb-wrapper">
              {testimonies.map((testimony, index) => (
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img src={testimony.fields.image.fields.file.url} alt="Testimonial Images" />
                    </div>
                  </div>
                </Tab>
              ))}
                 
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
 
export default Testimonial;

import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

function Footer() {
  const { t } = useTranslation();

    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>  {t('footer.readyToDoThis')}</span>
                    <h2>
                      {t('footer.letget')} <br />  {t('footer.towork')} 
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span> {t('contact.title')} </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="7">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4> {t('footer.quicklinks')} </h4>
                        <ul className="ft-link">
                          <li>
                            <a href="/portfolio">{t('footer.work')}</a>
                          </li>
                          <li>
                            <a href="/about">{t('footer.about')}</a>
                          </li>
                          <li>
                            <a href="/contact">{t('footer.letsTalk')}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>{t('footer.sayHello')}</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:admin@example.com">
                              admin@lessential.com
                            </a>
                          </li>
                          <li>
                            <a href="mailto:hr@example.com">hr@lessential.com</a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © 2024 Lessesntial. {t('footer.allRights')}.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }

export default Footer;

// contentful.js (your existing helper file)
const contentful = require("contentful");

const client = contentful.createClient({
  space: "dbwq3hx1ndgn",
  accessToken: "7tcMJ6QStTVXyfPoa3ZstrbbgMrBzADcJQEGqNTQivc",
});

const fetchEntries = async (contentType, locale = "en-US") => { // Default to 'en-US'
  try {
    const entries = await client.getEntries({
      content_type: contentType,
      locale: locale, // Add the locale parameter
    });
    return entries.items;
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling in the component
  }
};

const fetchEntry = async (entryId, locale = "en-US") => { // Default to 'en-US'
  try {
    const entry = await client.getEntry(entryId, { locale: locale }); // Add the locale parameter
    return entry;
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling in the component
  }
};

module.exports = { fetchEntries, fetchEntry };

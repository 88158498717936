import React, { useEffect } from 'react';
import { ArrowRight } from 'react-feather';
import ListOne from '../component/common/List';
import { useDispatch, useSelector } from 'react-redux';
import { use } from 'i18next';
import { fetchServices } from '../component/common/servicesSlice';
import { useTranslation } from 'react-i18next';
import { FiCast } from 'react-icons/fi';


const ListComponent = () => {
    const dispatch = useDispatch();
    const services = useSelector(state => state.services.services);
    const loading = useSelector(state => state.services.loading);
    const { i18n, t } = useTranslation();
    const language = i18n.language;


    useEffect(() => {
        const locale = language === 'en' ? 'en-US' : 'fr-CM';
        dispatch(fetchServices(locale));


    }
        , [dispatch, language]);
    console.log('services:', services);
    return (


        
                <div className="row creative-service">
                    {services.map((service, index) => (
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                          <a href="">    
                            <div className="single-column service service__style--2">
                            <div className="icon">
                            <FiCast />
                            </div>
                            <div className="content">
                                <h4 className="tilte"> {service.fields.title}</h4>
                                <p> {service.fields.description}</p>
                                <ListOne service={service} />
                                </div>
                            </div>
                            </a>  
                        </div>
                    ))}
                </div>
           


    );
};

export default ListComponent;
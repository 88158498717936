import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEntries, fetchEntry } from '../../component/common/contentful';

const initialState = {
    about:{},
    whyChooseUs: { },
    approach:{},
    mission:[],
    vision:[],
    loading: false,
    error: null,
};

export const fetchAbout = createAsyncThunk('about/fetchAbout', async (locale) => {
    console.log('Fetching about...');
    try {
        const response = await fetchEntry('2AnzBTbsDKaZIE8BbHdRON', locale);
        console.log(response);
        return response; // Return the fetched data
    } catch (error) {
        console.error('Error fetching about:', error);
        throw error;  // Re-throw the error for Redux Toolkit to handle
    }
}
) ;
export const fetchWhyChoose =createAsyncThunk('about/fetchWhyChoose', async (locale) => {
    console.log('Fetching whychoose us...');
    try {
        const response = await fetchEntry('1UrE4HmLsbjYfvyYAat2M0', locale);
        console.log( "whychoose", response);
        return response; // Return the fetched data
    } catch (error) {
        console.error('Error fetching about:', error);
        throw error;  // Re-throw the error for Redux Toolkit to handle
    }
}


);
export const fetchMission =createAsyncThunk( 'about/fetchMission', async (locale)=>{
    console.log ("fetching mission");
try {
    const response = await fetchEntries("topicBodyContent", locale);
    console.log("mission", response)
    return response
    
} catch (error) {
    console.error('Error fetching mission:', error);
    throw error
}
}

)
export const fetchVision =createAsyncThunk( 'about/fetchVision', async (locale)=>{
    console.log ("fetching vision");
try {
    const response = await fetchEntries("notreVision", locale);
    console.log("vision", response)
    return response
    
} catch (error) {
    console.error('Error fetching vision:', error);
    throw error
}
}
)
export const fetchOurAppraoch =createAsyncThunk( 'about/fetchOurAppraoch', async (locale)=>{
    console.log ("fetching our approach");
    try {
        const response = await fetchEntry("5W3JMfF8YLb69D6Eqs1rLY", locale);
        console.log("our approach", response)
        return response
    } catch (error) {
        console.error('Error fetching vision:', error);
        throw error
    }
    }
    )

    export const fetchOurcommitment =createAsyncThunk( 'about/fetchOurcommitment', async (locale)=>{
        console.log ("fetching our commitment");
        try {
            const response = await fetchEntry("Ug1AZjxOdHjh7KqpvuLkS", locale);
            console.log("our commitment", response)
            return response
        } catch (error) {
            console.error('Error fetching vision:', error);
            throw error
        }
        }
        )

const aboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAbout.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAbout.fulfilled, (state, action) => {
                state.loading = false;
                state.about = action.payload.fields;
            })
            .addCase(fetchAbout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchWhyChoose.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWhyChoose.fulfilled, (state, action) => {
                state.loading = false;
                state.whyChooseUs = action.payload.fields;
            })
            .addCase(fetchWhyChoose.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchMission.fulfilled, (state, action) => {
                state.loading = false;
                state.mission = action.payload;
            })
            .addCase(fetchMission.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchVision.fulfilled, (state, action) => {
                state.loading = false;
                state.vision = action.payload;
            })
            .addCase(fetchOurAppraoch.fulfilled, (state, action) => {
                state.loading = false;
                state.approach = action.payload.fields;
            })
            .addCase(fetchOurcommitment.fulfilled, (state, action) => {
                state.loading = false;
                state.ourcommitment = action.payload.fields;
            }
            )
            ;
    },
});
 

 

export default aboutSlice.reducer;
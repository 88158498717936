import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPortfolios } from "../../../elements/portfolio/portFolioSlice";
import { useTranslation } from "react-i18next";



const Portfolio = () => {
    const dispatch = useDispatch();
    const portfolioList = useSelector((state) => state.portfolio.portfolios);
    console.log(portfolioList);
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (portfolioList.length === 0 || portfolioList === undefined || portfolioList === undefined) {
            console.log('fetching blogs');
            dispatch(fetchPortfolios());
        }


    }, [dispatch]);
    let title = "{t{about.ourWorks}}",
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
    return (
        <React.Fragment>
            <div className="portfolio-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title">
                                <h2>{t("about.ourWorks")}</h2>
                                <p>{t("about.ourWorkDetails")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="portfolio-slick-activation mt--70 mt_sm--40">
                    <Slider {...portfolioSlick2}>
                        {portfolioList.map((value, index) => {

                            const thumbnail = value.fields.ownerImage.fields.file.url;
                            const company = value.fields.company;
                            const name = value.fields.name;

                            return (
                                <div className="portfolio " key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`} style={{ backgroundImage: `url("${thumbnail}")` }}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{name}</p>
                                            <h4 className="title"><a href={`/portfolio-details/${company?.replace(/\s+/g, '-')}`}>   {company}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={`/portfolio-details/${company?.replace(/\s+/g, '-')}`}>Case Study</a>
                                            </div>
                                        </div>
                                    </div>
                                    <Link className="link-overlay" to={`/portfolio-details/${company?.replace(/\s+/g, '-')}`}></Link>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )

}
export default Portfolio;
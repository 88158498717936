import { configureStore } from '@reduxjs/toolkit'
import blogReducer from './elements/blog/blogSlice'
import aboutReducer from './elements/about/aboutSlice'
import portfolioReducer from './elements/portfolio/portFolioSlice'
import servicesReducer from './component/common/servicesSlice'

export const store = configureStore({
  reducer: {
    blog: blogReducer,
    about: aboutReducer,
    portfolio: portfolioReducer,
    services: servicesReducer,
  },
})







import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

const Result = ({ success }) => (
    <p className={`message ${success ? 'success' : 'error'}`}>
        {success
            ? 'Your message has been successfully sent. I will contact you soon.'
            : 'There was an error sending your message. Please try again.'}
    </p>
);

function ContactForm() {
    const [result, showResult] = useState(null); // null, true (success), false (error)
    const [isLoading, setIsLoading] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setIsLoading(true);

        emailjs.sendForm(
            'service_o3p531o', // Replace with your actual Service ID
            'template_8mmlgi8', // Replace with your actual Template ID
            form.current,
            'p55rvwsikjOLdXD-T',
            {
                name: form.current.fullname.value, // Assuming your name input field has the name "fullname"
                email: form.current.email.value,
                message: form.current.message.value,
                phone: form.current.phone.value,
                subject: form.current.subject.value,
            })
                .then((result) => {
                    console.log(result.text);
                    showResult(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        showResult(null);  
                    }, 5000);
                    form.current.reset();
                }, (error) => {
                    console.error('Email Error:', error);
                    showResult(false);
                    setIsLoading(false);
                    setTimeout(() => {
                        showResult(null); // Reset result after 5 seconds
                    }, 5000);

                })

      
    
       
        };

    return (
        <form ref={form} onSubmit={sendEmail}>
            {/* ... your input fields with aria-label attributes ... */}
            <div className="rn-form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                />
            </div>

            <div className="rn-form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                >
                </textarea>
            </div>
            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid submit" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Submit Now'}
                </button>
            </div>
            <div className="rn-form-group">
                {result !== null && <Result success={result} />}
            </div>

        </form>
    );
}

export default ContactForm;

import React, { Component } from "react";
import { FiCheck } from "react-icons/fi";
import ReactMarkdown from 'react-markdown'

class ListOne extends Component {
    render() {
        // Use names from props
        const { service } = this.props;
        console.log('services:', service);

        return (
            <ul className="list-style--1">

                {[...Array(5).keys()].map((index) => {
                    const pointNumber = index + 1;
                    const pointKey = `point${pointNumber}`;
                    return (
                        service?.fields?.[pointKey] && (
                            <li key={pointKey}>
<div className="inline-content">
  <FiCheck />
  <div>
    <ReactMarkdown>
      {service.fields[pointKey]}
    </ReactMarkdown>
  </div>
</div>
                               
                            </li>
                        )
                    );
                })}

            </ul>
        );
    }
}

export default ListOne;
import React from 'react'
import { useTranslation } from 'react-i18next';
const CallAction = () => {
    const {  t } = useTranslation();
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <span>{t("callToAction.title")}</span>
                            <h2>{t("callToAction.subtitle")}</h2>
                            <a className="rn-button-style--2" href="/contact"><span>{t("callToAction.button")}</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallAction;
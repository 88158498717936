import React, { Component } from "react";
import ContactForm from "./ContactForm";

import about from "../../assets/images/about/bg-temp.jpg";
import { useTranslation } from "react-i18next";

const ContactTwo = () =>{
  const { i18n ,t} = useTranslation();
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{t("contactUs.pageTitle")}</h2>
                <p className="description"> 
                  {t("contactUs.contactShortDescription")}
                </p>
              </div>
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src={about} alt="lessential" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
 
export default ContactTwo;

import React from "react";
import { DiscussionEmbed, CommentCount as DisqusCommentCount } from "disqus-react";

function DisqusComments({ identifier, title, url }) {
    const disqusConfig = {
        shortname: "lessential",
        config: { identifier, title, url, language: 'en' },  
    };

    return (
        <div className="disqus-comments">
            <DiscussionEmbed {...disqusConfig} />
        </div>
    );
}

function CustomCommentCount({ identifier, title, url }) {  
    const disqusConfig = {
        shortname: "lessential",
        config: { identifier, title, url },
    };
    return (
        <div className="comment-count">
            <DisqusCommentCount {...disqusConfig}>Comments</DisqusCommentCount>
        </div>
    );
}

export { DisqusComments, CustomCommentCount };  
import React, { Component } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";

import logoDefault from "../../assets/images/logo/logo.png";
import logoLight from "../../assets/images/logo/logo-light.png";
import logoDark from "../../assets/images/logo/logo-dark.png";
import logoSymbolDark from "../../assets/images/logo/logo-symbol-dark.png";
import logoSymbolLight from "../../assets/images/logo/logo-symbol-light.png";
import logo2 from "../../assets/images/logo/logo2.png";
import logo3 from "../../assets/images/logo/logo3.png";
import AppLanguage from "../../elements/language";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" }, 
];
class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
    this.state = {
      color: this.props.color || 'default-color',
      hamburgerColor: 'white',
    };
    this.handleScroll = this.handleScroll.bind(this); // Bind the function to the correct context

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  closeSidebar() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const value = window.scrollY;
    if (value > 100) {
      document.querySelector('.header--fixed').classList.add('sticky');
      this.setState({ color: 'color-black' }); // replace 'new-color' with the color you want
      this.setState({ hamburgerColor: 'black' });
    } else {
      document.querySelector('.header--fixed').classList.remove('sticky');
      this.setState({ color: this.props.color || 'default-color' });
      this.setState({ hamburgerColor: 'white' });
    }
  }

  render() {
    // window.addEventListener("scroll", function () {
    //   var value = window.scrollY;
    //   if (value > 100) {
    //     document.querySelector(".header--fixed").classList.add("sticky");
    //     this.setState({ color: 'color-black' });  
    //   } else {
    //     document.querySelector(".header--fixed").classList.remove("sticky");
    //     this.setState({ color: this.props.color || 'default-color' });
    //   }
    // });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo } = this.props;
    const { color } = this.state;
    const {hamburgerColor} = this.state;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src={logoLight} alt="Digital Agency" />;
    } else if (logo === "dark") {
      logoUrl = <img src={logoDark} alt="Digital Agency" />;
    } else if (logo === "symbol-dark") {
      logoUrl = <img src={logoSymbolDark} alt="Digital Agency" />;
    } else if (logo === "logo2") {
      logoUrl = <img src={logo2} alt="Digital Agency" />;
    }  else if (logo === "logo3") {
      logoUrl = <img src={logo3} alt="Digital Agency" />;
    }  else if (logo === "symbol-light") {
      logoUrl = <img src={logoSymbolLight} alt="Digital Agency" />;
    } else {
      logoUrl = <img src={logoDefault} alt="Digital Agency" />;
    }
    

    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={this.props.homeLink}>{logoUrl}</a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <ul className="mainmenu">
                <li>
                  <a onClick={this.closeSidebar} href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a onClick={this.closeSidebar} href="/about">
                    About
                  </a>
                </li>
                <li>
                  <a onClick={this.closeSidebar} href="/service">
                    Service
                  </a>
                </li>
                <li>
                  <a onClick={this.closeSidebar} href="/portfolio">
                  Success Stories
                  </a>
                </li>
                <li>
                  <a onClick={this.closeSidebar} href="/blog">
                    Blog
                  </a>
                </li>
                <li>
                  <a onClick={this.closeSidebar} href="/contact">
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className={`social-share social-style--2 ${color} d-flex justify-content-start liststyle `}>
                <AppLanguage />
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="header-btn">
              <a
                className="rn-btn"
                href="/contact"
              >
                <span>Contactez Nous</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu color={ hamburgerColor} />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;

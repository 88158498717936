export const PortfolioListContent = [
    {
        image: 'image-1',
        name: 'LISSOUK MOUAHA FLORENCE',
        company: 'Bikalo Green'
    },
    {
        image: 'image-2',
        name: 'Giresse BATOUM Madeleine',
        company: 'Women in Moov'
    },
    {
        image: 'image-3',
        name: 'SIDONIE TETGA KONA epse bada',
        company: 'BUILD AGRO DEVELOPMENT AREA SARL'
    },
    {
        image: 'image-4',
        name: 'NGON ESSONO JEAN CHARLES',
        company: 'Loverlo'
    },
    {
        image: 'image-5',
        name: 'ONGMESSOM Blanche',
        company: 'DAVEN BAKERY'
    },
    {
        image: 'image-6',
        name: ' HAPPI DJOMKAM FRANCK GEORDI ',
        company: 'Happi Group Industry'
    }
    ,
    {
        image: 'image-7',
        name: 'Madame Ndzie nee Enyegue Suzanne sveltana',
        company: 'sveltcao'
    }
    ,
    {
        image: 'image-8',
        name: 'BIWONO ONANA CELESTIN ULRICH',
        company: 'La SOCATRA SARL'
    }
    ,
    {
        image: 'image-9',
        name: 'NGNEDJOU Francoise FOUTE epouse NWABUFO',
        company: 'ONYI Healthy Food'
    }
    ,
    {
        image: 'image-11',
        name: 'HALIMATOU Epse ADJIA Madeleine',
        company: 'Hali-Agroma'
    }
    ,
    {
        image: 'image-10',
        name: 'sonia balepa',
        company: 'Cissy’s Hair production & Promotion des produits Made in Cameroon à l’international'
    }
]
import React, { Component, useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { useParams, useLocation } from 'react-router-dom';

import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

import portfolio1 from "../assets/images/portfolio/portfolio-big-01.jpg";
import portfolio2 from "../assets/images/portfolio/portfolio-big-02.jpg";
import portfolio3 from "../assets/images/portfolio/portfolio-big-03.jpg";
import relatedImg1 from "../assets/images/portfolio/related-image-01.jpg";
import relatedImg2 from "../assets/images/portfolio/related-image-02.jpg";
import HeaderThree from "../component/header/HeaderThree";
import { PortfolioListContent } from "./portfolio/portfolioData";
import { useSelector, useDispatch } from "react-redux";
import { fetchPortfolios } from "./portfolio/portFolioSlice";

const PortfolioDetails = () => {

  let { company } = useParams();

  const formattedCompany = company.replace(/-/g, ' ');
  const portfolioList = useSelector((state) => state.portfolio.portfolios);
  const [portfolio, setPortfolio] = useState({});
  const dispatch = useDispatch();



  const updatePortfolio = (portfolio) => {
    setPortfolio(portfolio);
  }
  useEffect(() => {
    async function fetchData() {
      if (portfolioList.length === 0 || portfolioList === undefined) {
        console.log('fetching blogs');
        dispatch(fetchPortfolios());
      } else {
        console.log('portfolioList:', portfolioList);
        const matchingCompanyObject = portfolioList.find(item => item.fields.company === formattedCompany);
        console.log('matchingCompanyObject:', matchingCompanyObject);
        updatePortfolio(matchingCompanyObject.fields);
      }
    }
    fetchData();
  }, [portfolioList, dispatch, formattedCompany, updatePortfolio]);
  return (
    <React.Fragment>
      <PageHelmet pageTitle={portfolio.company ?? "Loading"} />

      <HeaderThree homeLink="/" logo="logo2" color="color-white" />


      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image "
        style={{
          backgroundImage: `url(${portfolio?.images?.[0]?.fields?.file?.url || 'defaultImageUrl'})`,    
        }}
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  {portfolio.company}
                </h2>
                <p>
                  {portfolio.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--20 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                  <div className="row">
                    <div className="col-md-6 portfolio-main-image">
                      <img src={portfolio.ownerImage?.fields?.file?.url} alt={portfolio.name} />
                      <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                    <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                      {SocialShare.map((val, i) => (
                        <li key={i}>
                          <a href={`${val.link}`}>{val.Social}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                    </div>

                    <div className="col-md-6">
                      <h2 style={{ margin: "20px 0", lineHeight: "1"}}>{portfolio.name}</h2>
                      <p className="subtitle">
                        {portfolio.intro}
                      </p>
                    </div>
                  </div>
 
                 
                </div>

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--12 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                   
                  <p className="subtitle">
                  <ReactMarkdown  > 
                  { portfolio.body}
                    </ReactMarkdown> ,
                  
                  </p>
                 

                  <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                    <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                      {SocialShare.map((val, i) => (
                        <li key={i}>
                          <a href={`${val.link}`}>{val.Social}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}
                <div className="portfolio-thumb-inner">
                  {
                    portfolio.images?.map((image, index) => {
                      return (
                        <div className="thumb position-relative mb--30" key={index}>
                          <img src={image.fields.file.url} alt="Portfolio Images" />
                        </div>
                      )
                    })
                  }
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      
      

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );


}
export default PortfolioDetails;

import React, { Component, Fragment } from "react";
import BlogContent from "./BlogContent";
import { useTranslation } from "react-i18next";

const BLogList  =({post})  => {
  
    const PostList = post.slice(0, 6);
    const { t } = useTranslation();
    return (
      <Fragment>
        <div className="row">
          {PostList.map((value, i) => { 
            const blogImage = value.fields.featuredImage.fields.file.url;
            const blogTitle = value.fields.title;
            const blogDescription = value.fields.description;
            const blogCategory = value.fields.category;
            const blogDate = value.fields.date;
            const blogAuthor = value.fields.author;
            const blogSlug = value.fields.slug;
            const blogId = value.sys.id;
            const blogUrl = `/blog-details?id=${blogId}`;
            console.log(blogUrl)
            return (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="blog blog-style--1">
                <div className="thumbnail">
                  <a  href={blogUrl}>
                    <img
                      className="w-100"
                      src={blogImage}
                      alt="Blog Images"
                    />
                  </a>
                </div>
                <div className="content">
                  <p className="blogtype">{blogCategory}</p>
                  <h4 className="title">
                    <a href="/blog-details">{blogTitle}</a>
                  </h4>
                  <div className="blog-btn">
                    <a className="rn-btn text-white" href={blogUrl}>
                     {t('blog.readMore')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )})}
        </div>
      </Fragment>
    );
  }

export default BLogList;

import React, { Component, Fragment, useEffect, useState } from "react";  // No need for Component import in functional components
import { Link } from "react-router-dom"; // Correct import for routing
import { PortfolioListContent } from "./portfolioData";
import { useSelector, useDispatch } from "react-redux";
import { fetchPortfolios } from "./portFolioSlice";

function PortfolioList(props) {
    const { column, styevariation, item } = props;
    const list = PortfolioListContent.slice(0, item);
    const dispatch = useDispatch();
    const portfolioList = useSelector((state) => state.portfolio.portfolios);
    console.log(portfolioList);

    useEffect(() => {
        if (portfolioList.length === 0 || portfolioList === undefined || portfolioList === undefined)  {
            console.log('fetching blogs');
        dispatch(fetchPortfolios());
            }

        
    }, [dispatch]);

    return (
        <React.Fragment>
            {portfolioList.map((value, index) => {

 
                const thumbnail = value.fields.ownerImage.fields.file.url;
                const company = value.fields.company;
                const name = value.fields.name;

                return (
                    <div className={column} key={index}>
                        <Link to={`/portfolio-details/${company?.replace(/\s+/g, '-')}`}>
                            <div className={`portfolio ${styevariation}`}>
                                <div className="thumbnail-inner">
                                    <div className={`thumbnail ${value.image}`} style={{ backgroundImage: `url("${thumbnail}")` }}></div>
                                    <div className={`bg-blr-image ${value.image}`} />
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{name}</p>
                                        <h4>
                                            <Link to={`/portfolio-details/${company?.replace(/\s+/g, '-')}`}>
                                                {company}
                                            </Link>
                                        </h4>
                                        <Link
                                            className="rn-btn"
                                            to={`/portfolio-details/${company?.replace(/\s+/g, '-')}`}

                                        >
                                            View Details
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }


            )}
        </React.Fragment>
    );
}

export default PortfolioList;

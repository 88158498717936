import React, { Component } from "react";

import brand1 from "../assets/images/brand/Untitled (1).png";
import brand2 from "../assets/images/brand/WOMEN IN MOOV.png";
import brand3 from "../assets/images/brand/images.jpeg";
import brand4 from "../assets/images/brand/images.png";
import brand5 from "../assets/images/brand/logo-65.png";
 

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand2} alt="Logo Images" />
          </li>
          <li>
            <img  src={brand3} alt="WOMEN IN MOOV" />
          </li>
          <li>
            <img src={brand4} alt="Logo Images" />
          </li>
          <li>
            <img src={brand5} alt="Logo Images" />
          </li>
           
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;

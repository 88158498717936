import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import ListComponent from "./list";
import { useTranslation } from "react-i18next";


 
const Service  = () =>{
    const { i18n ,t} = useTranslation();
     
        return(
            <React.Fragment>
                <PageHelmet pageTitle={t("services.pageTitle")} />
                <HeaderThree homeLink="/" logo="logo2" color="color-white" />
      
                {/* Start Breadcrump Area */}
                <Breadcrumb title={t("services.breadcrumbTitle")}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>{t("services.pageTitle")}</h2>
                                    <p>{t("services.ourServicesDetails")}</p>
                                </div>
                            </div>
                        </div>
                        <ListComponent/>
                         
                    </div>
                </div>
                {/* End Service Area */}

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
 
export default Service;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchEntries, fetchEntry } from './contentful';

const initialState = {
    services: [],
    service: {},
    approach: {},
    slide:[],
    testimonials:[],
 
    loading: false,
    error: null,
};


export const fetchServices = createAsyncThunk('services/fetchServices', async (locale) => {
    console.log('Fetching services...');
    try {
        const response = await fetchEntries('nosServices', locale);
        return response; // Return the fetched data
    } catch (error) {
        console.error('Error fetching services:', error);
        throw error;  // Re-throw the error for Redux Toolkit to handle
    }
}, );
export const fetchService= createAsyncThunk('services/fetchService', async (locale) => {
    console.log('Fetching services...');
    try {
        const response = await fetchEntry('5jItVWFlKkJipFeG9SYuLf', locale);
        return response; // Return the fetched data
    }
    catch (error) {
        console.error('Error fetching services:', error);
        throw error;  // Re-throw the error for Redux Toolkit to handle
    }
}

);
export const fetchTestimonials = createAsyncThunk('services/fetchTestimonials', async (locale) => {

    try {
        const response = await fetchEntries('testimonial', locale);
        return response; // Return the fetched data
    }
    catch (error) {
        console.error('Error fetching services:', error);
        throw error;  // Re-throw the error for Redux Toolkit to handle
    }
}
);


export const fetchOurApproach = createAsyncThunk('services/fetchOurApproach', async (locale) => {
    console.log('Fetching services...');
    try {
        const response = await fetchEntry('4HccjvwZ6SgYEolOqZ6gyz', locale);
        return response; // Return the fetched data
    }
    catch (error) {
        console.error('Error fetching services:', error);
        throw error;  // Re-throw the error for Redux Toolkit to handle
    }
}
);

export const fetchSlide = createAsyncThunk('services/fetchSlide', async (locale) => {
    console.log('Fetching slides...');
    try {
        const response = await fetchEntries('slide', locale);
        return response; // Return the fetched data
    }
    catch (error) {
        console.error('Error fetching slides:', error);
        throw error;  // Re-throw the error for Redux Toolkit to handle
    }
}
);

const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
         
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchServices.pending, (state) => {
                state.loading = true;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchServices.fulfilled, (state, action) => {
                state.loading = false;
                state.services = action.payload.slice().reverse();;
            })
            .addCase(fetchServices.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; // Set the error message
            })
             
            .addCase(fetchService.fulfilled, (state, action) => {
              
                state.service = action.payload;
            })
            
            .addCase(fetchOurApproach.fulfilled, (state, action) => {
              
                state.approach = action.payload;
            })

            .addCase(fetchSlide.fulfilled, (state, action) => {
                  
                 state.slide = action.payload;
                }
            )
            .addCase(fetchTestimonials.fulfilled, (state, action) => {
                    
                 state.testimonials = action.payload;
                }
            )
            ;
           
    },
});

 

export default servicesSlice.reducer;
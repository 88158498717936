// React Required
import React, { Component, } from "react";


// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout

import Business from "./home/Business";



// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import Error404 from "./elements/error404";

// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import ContactOne from "./elements/contact/ContactOne";


export default function Root() {

  return (
    <BrowserRouter basename={"/"}>
      <PageScrollTop>
        <Routes>
          <Route path={`/`} element={<Business />} />

       
          <Route

            path={`/service`}
            element={<Service />}
          />
          <Route

            path={`/service-details`}
            element={<ServiceDetails />}
          />
          <Route

            path={`/contact`}
            element={<Contact />}
          />
          <Route

            path={`/about`}
            element={<About />}
          />
          <Route

            path={`/portfolio-details/:company`}
            element={<PortfolioDetails />}
          />
          <Route

            path={`/blog`}
            element={<Blog />}
          />
          <Route

            path={`/blog-details`}
            element={<BlogDetails />}
          />

          {/* Blocks Elements  */}


          <Route

            path={`/testimonial`}
            element={<Testimonial />}
          />
          <Route

            path={`/portfolio`}
            element={<Portfolio />}
          />

          <Route

            path={`/gallery`}
            element={<Gallery />}
          />



<Route path={`/404`} element={<Error404 />} />
<Route path="*" element={<Error404 />} />
        </Routes>
      </PageScrollTop>
    </BrowserRouter>
  );
}




import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


const Accordion01 = ({ approach = {} }) => { // Default approach to an empty object if undefined
    return (
        <Accordion className="accodion-style--1" preExpanded={'0'}>
            {Object.entries(approach || {}).map(([key, value], index) => ( // Additional check for safety
                <AccordionItem key={key}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {value.split(' : ')[0]}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>{value.split(' : ')[1]}</p>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

export default Accordion01;






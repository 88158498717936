import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function AppLanguage() {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage); // Save to localStorage
  };

  // Load language from localStorage on component mount
  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <React.Fragment>
      <button className="App-language-toggle" onClick={toggleLanguage}>
        {i18n.language === 'en' ? (
          <span>🇫🇷</span>
        ) : (
          <span>🇬🇧</span>
        )}
      </button>
    </React.Fragment>
  );
}

export default AppLanguage;

 import React, { useState,useEffect } from "react";
 import { useLocation } from "react-router-dom"; 
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser, FiMessageCircle, FiHeart, FiChevronUp } from "react-icons/fi";
import { Link,useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

 
import { useSelector, useDispatch } from 'react-redux';

import imgOne from "../assets/images/blog/bl-big-01.jpg";
import imgTwo from "../assets/images/blog/blog-single-01.png";
import { fetchBlogs } from "./blog/blogSlice";
import HeaderThree from "../component/header/HeaderThree";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { CustomCommentCount, DisqusComments } from "../disque";
import { useTranslation } from "react-i18next";

const BlogDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();  
  const navigate = useNavigate(); // Create an instance of useNavigate
  const blogs = useSelector((state) => state.blog.blogs);
  const [specificBlog, setSpecificBlog] = useState({});
  const { i18n } = useTranslation();
  const language = i18n.language;


  useEffect(() => {
    console.log('Fetching blogs.. at blog details page.');
    const locale = language === 'en' ? 'en-US' : 'fr-CM';
    const queryParams = new URLSearchParams(location.search);  
    const id = queryParams.get('id');

    // Early return if there is no 'id'
    if (!id) {
      navigate('/blog');
      return; 
    }

    
    const foundBlog = blogs.find(b => b.sys.id === id && b.sys.locale === locale);
    console.log('Found blog:', foundBlog);
    if (foundBlog) {
      if (foundBlog.sys.locale !== locale) { 
        dispatch(fetchBlogs(locale));  
      }
      else{
        setSpecificBlog(foundBlog)
      }
      ; 
    } else if (blogs.length === 0 || foundBlog === undefined) {
       
      dispatch(fetchBlogs(locale));  
    }
  }, [location, navigate, blogs, dispatch, language]); 


  const postDetails = {
    identifier: specificBlog?.sys?.id,  
    title: specificBlog?.fields?.title,
    url: specificBlog?.fields?.slug,  
  };
 

  return (
    <React.Fragment>
      <PageHelmet pageTitle={"Blog Details"} />
      <HeaderThree homeLink="/" logo="logo2" color="color-white" />

      {specificBlog && specificBlog.fields ? (
        <div>

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                {specificBlog.fields.title};
                </h2>
                <ul className="blog-meta d-flex justify-content-center align-items-center">
                  <li>
                    <FiClock />
                    {new Date(specificBlog.fields.date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })}
                  </li>
                  <li>
                    <FiUser />
                    Lessential
                  </li>
                  <li>
                    <FiMessageCircle />
                    <CustomCommentCount {...postDetails} />  
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--20 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                  {documentToReactComponents(specificBlog.fields.body)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}
    
      

      {/* Start BLog Comment Form  */}
      <div className="blog-comment-form pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                <DisqusComments {...postDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End BLog Comment Form  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      </div>
    ) : (
      // Display a message or component when specificBlog is null or empty
      <div>No blog details available.</div>
    )}
      <Footer />
    </React.Fragment>
  );
};

export default BlogDetails;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEntries } from '../../component/common/contentful';

// Define the initial state
const initialState = {
    portfolios: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  };


// Define an async thunk for fetching portfolios
export const fetchPortfolios = createAsyncThunk(
  'portfolio/fetchPortfolios',
  async () => {
  try { 
    const response = await fetchEntries('portfolio');
    return response;
  }
    catch (error) {
        console.error('Error fetching portfolios:', error);
        throw error;
    }
}
);



// Create the slice
const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolios.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPortfolios.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
        state.portfolios = action.payload;
      })
      .addCase(fetchPortfolios.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default portfolioSlice.reducer;
import i18n from "i18next";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next"; 
import LanguageDetector from 'i18next-browser-languagedetector';

const languageDetector = new LanguageDetector(null, {
  order: ['localStorage', 'navigator', 'htmlTag'],
  caches: ['localStorage'],
});

i18n
  .use(backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development", // Enable debug logs in dev
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: "languageChanged",
      useSuspense: false,
    },
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage']
    }
  })
  .then(() => {
    // Optional: Set the document's dir attribute based on the language direction
    document.documentElement.dir = i18n.dir();
  });

export default i18n;

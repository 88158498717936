 
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import Root from './app';  
import {store} from './store';  
import * as serviceWorker from './serviceWorker';  
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const AppWithProvider = () => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Root />
    </Provider>
    </I18nextProvider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppWithProvider />);
serviceWorker.register();